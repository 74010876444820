<template>

  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-9 subnav">
        <router-link to="/raportointi"><h1>Huoltoilmoitukset</h1></router-link>
      </div>
      <div class="col-12 col-lg-3 text-right">
        <download-csv :data="items" delimiter=";" encoding="utf-8" name="huoltoilmoitukset.csv" class="btn btn-new btn-lg d-inline-block" v-if="!loading && items.length > 0"><i class="fas fa-file-csv"></i> Lataa csv-tiedosto</download-csv>
      </div>

      <div class="col-12">
        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

        <div class="filters">
          <button type="button" class="btn btn-sm btn-primary mt-3 float-right" v-on:click="load()">Suodata</button>

          <div class="filter pt-2"><label>PVM alku</label><input v-model="filters.date_start" type="date" placeholder="" class="form-control form-control-sm d-inline-block" /></div>
          <div class="filter pt-2"><label>PVM loppu</label><input v-model="filters.date_end" type="date" placeholder="" class="form-control form-control-sm d-inline-block" /></div>
          <div class="filter">
            <multiselect v-model="filters.site" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :showLabels="false" placeholder="Kohteet" :options="sites.map(site => site.id)" :custom-label="opt => sites.find(x => x.id == opt).name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span v-if="values.length == 1">kohde</span><span v-if="values.length > 1">kohdetta</span></span></template>
              <template slot="tag">{{ '' }}</template>
              <template slot="noResult">Kohteita ei löydetty.</template>
            </multiselect>
          </div>
          <div class="filter">
            <multiselect v-model="filters.type" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :showLabels="false" placeholder="Tyypit" :options="types.map(type => type.id)" :custom-label="opt => types.find(x => x.id == opt).name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span v-if="values.length == 1">tyyppi</span><span v-if="values.length > 1">tyyppiä</span></span></template>
              <template slot="tag">{{ '' }}</template>
              <template slot="noResult">Tyyppejä ei löydetty.</template>
            </multiselect>
          </div>
				</div>

				<b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

        <div class="container">
          <div class="row">
            <div class="col-12 py-4">
              <GChart type="ColumnChart" :data="chart" :options="chartOptions" />
            </div>
            <div class="col-12">
              <table class="admin-list" v-if="!loading && visible > 0">
                <tr>
                  <th>PVM <i class="fas fa-chevron-down" v-on:click="sort = 'created:asc'" v-if="sort != 'created:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'created:desc'" v-if="sort != 'created:desc'"></i></th>
                  <th>Valmis</th>
                  <th>Ilmoitus</th>
                  <th>Asuntonro</th>
                  <th>Suorittanut</th>
                  <th>Kuvia</th>
                  <th>Suorituksia ajanjaksolla</th>
                </tr>
                <tr v-for="(item) in items" :key="item.id" :id="['ticket-' + item.id]">
                  <td class="w120"><p><span class="date">{{ item.created | formatDate }}</span></p></td>
                  <td class="text">
                    <p class="blue" v-if="item.status == 3">{{ item.closed | formatDate }}</p>
                    <p class="blue" v-if="item.status < 3">kesken</p>
                    <p class="blue" v-if="item.status == 4">{{ item.recurring_interval }} pvä</p>
                  </td>
                  <td class="text"><p><strong><router-link :to="{ name: 'Huoltoilmoitukset', query: { id: item.id } }">{{ item.title }}</router-link></strong></p></td>
                  <td class="text"><p class="blue">{{ item.site_name }}<br/><strong>{{ item.site_address }}</strong></p></td>
                  <td class="text"><p>{{ item.closed_by_name }}</p></td>
                  <td><p>{{ item.img_count }} kpl</p></td>
                  <td><p v-if="item.status == 4">{{ item.check_count }} kertaa</p></td>
                </tr>
              </table>
            </div>
          </div>
        </div>

			</div>
		</div>
	</div>

</template>

<script>
import { GChart } from 'vue-google-charts'
import moment from 'moment'

export default {
  components: {
    GChart
  },
	data: function () {
		return {
			loading: true,
			error: false,
      items: [],
			sites: [],
      areas: [],
      types: [],
      filters: {
        site: '',
        type: '',
        date_start: moment().add(-3, 'month').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD')
      },
      chart: [ [ 'Kuukausi', 'Kpl' ], [ 0, 0 ] ],
      chartOptions: { title: 'Huoltoilmoitukset', height: 500 },
			sort: 'created:desc',
      visible: 0
		}
	},
	created: function () {
    var vm = this;
		this.axios.get('/api/sites?limit=500')
		.then(function (response) {
			if(response.status == 200) {
				vm.sites = response.data.results;
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    this.axios.get('/api/filters?limit=500')
		.then(function (response) {
			if(response.status == 200) {
        (response.data.results).forEach(filter => {
          if(filter.type == 1) vm.areas.push(filter);
          else if(filter.type == 2) vm.types.push(filter);
        })
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    vm.load();
	},
	methods: {
		load: function () {
			var vm = this;
      vm.error = false;
			vm.loading = true;
			var sort = vm.sort.split(':');
			this.axios.get('/api/reports?sort=' + sort[0] + '&dir=' + sort[1] + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.items = response.data.object.list;
          vm.chart = response.data.object.totals;
          vm.visible = response.data.object.list.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.loading = false;
			})
		},
		showpage: function (id) {
			this.page = id;
		}
	},
	watch: {
    sort: function () {
			this.load();
		},
	}
}
</script>
